/* eslint-disable compat/compat */
import { format, fromUnixTime } from 'date-fns'
import * as R from 'ramda'
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'
import * as m from '@/utils/messages'
import {mergeDeepRight} from "ramda";


const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

const arrayToObject = data =>
	data &&
	data.reduce(
		(acc, curr) => ({
			...acc,
			[curr.benefitId]: curr
		}),
		{}
	)

const filterBenefits = (benefits, beamSupportedBenefits) =>
{
		const filteredBenefits = benefits.filter(benefit => {

			if(benefit.beamSupported)
				return benefit

			if(!beamSupportedBenefits.includes(parseInt(benefit.benefitId)))
			{
				return benefit;
			}

		})

		return filteredBenefits
}

const initialState = {
	products: {},
	selections: {},
	ccvBalance: {},
	setup: {},
	statements: {},
	terms: {},
	questions: {},
	status: '',
	benefitsLoading: false,
	beamSupportedBenefits: [],
	refreshRequired: {
		benefitsAndSelections: false,
		mtb: {
			card: false,
			page: true
		}
	},
	beamCosts: {},
	beamCostRequestValues: {},
	wizardResult: {},
}

export default {
	state: initialState,
	reducers: {
		INIT_BENEFITS(state, payload) {
			return {
				...state,
				products: arrayToObject(payload)
			}
		},

		RESET_BEAM_COSTS_REQUEST_VALUES(state) {
			return {
				...state,
				beamCostRequestValues: {}
			}
		},

		INIT_SELECTIONS(state, payload) {
			const selections = arrayToObject(
				payload.map(({selectionId, ...selection}) => {
					return {
						...selection,
						selectionId,
						temporarySelection: false
					}
				})
			)

			return {
				...state,
				selections
			}
		},

		ADD_BENEFIT(state, payload) {
			return {
				...state,
				products: {
					...state.products,
					[payload.benefitId]: payload
				}
			}
		},

		ADD_BENEFITS(state, payload) {
			const mergedProducts = R.mergeDeepRight(
				arrayToObject(
					filterBenefits(payload, state.beamSupportedBenefits)
				),
				state.products
			)

			return {
				...state,
				products: mergedProducts,
			}
		},

		ADD_CCV_BALANCE(state, payload) {
			return {
				...state,
				ccvBalance: {
					...state.ccvBalance,
					lastPaymentDate: payload.lastPaymentDate
						? format(fromUnixTime(payload.lastPaymentDate), 'Y-MM-dd')
						: null,
					balance: payload.balance
				}
			}
		},

		ADD_SELECTIONS(state, payload)
		{
			const selections = payload.map(({ selectionId, ...selection }) => {
				return {
					...selection,
					selectionId
				}
			})
			const mergedSelections = R.mergeDeepRight(state.selections, arrayToObject(selections))
			return {
				...state,
				selections: mergedSelections
			}
		},

		ADD_SELECTION(state, payload) {
			const { id, selection, temporarySelection } = payload

			return {
				...state,
				selections: {
					...state.selections,
					[id]: {
						...state.selections[id],
						...selection,
						temporarySelection
					}
				}
			}
		},

		ADD_BENEFIT_DETAILS(state, payload) {
			const { id, ...rest } = payload

			return {
				...state,
				products: {
					...state.products,
					[id]: {
						...state.products[id],
						...rest
					}
				}
			}
		},

		ADD_STATEMENT(state, payload) {
			const { id, data } = payload

			return {
				...state,
				statements: {
					...state.statements,
					[id]: data
				}
			}
		},

		RESET() {
			return initialState
		},

		QUESTIONS_COMPLETE(state, payload) {
			const { questions } = state
			return {
				...state,
				questions: {
					...questions,
					[payload]: true
				}
			}
		},

		SET_TERMS(state, payload) {
			const { benefitId, termId, value } = payload
			const { terms } = state
			return {
				...state,
				terms: {
					...terms,
					[benefitId]: {
						[termId]: value
					}
				}
			}
		},

		SAVE_VALUES(state, payload) {
			const { id, values } = payload
			const current = state.setup[id]
			return {
				...state,
				status: 'SUCCESS',
				setup: {
					...state.setup,
					[id]: {
						...current,
						values
					}
				}
			}
		},

		SET_STATUS(state, payload) {
			return {
				...state,
				status: payload
			}
		},

		SET_SELECTION_FIELD_STATUS(state, { selectionId, fieldStatus }) {
			return {
				...state,
				selections:{
					...state.selections,
						[selectionId]: {
							...state.selections[selectionId],
							fieldStatus
						}
				}
			}
		},

		SET_REFRESH_REQUIRED(state, payload) {
			return {
				...state,
				refreshRequired: {
					...state.refreshRequired,
					...payload
				}
			}
		},

		SET_BENEFITS_LOADING_STATUS(state, { status, error }) {
			return {
				...state,
				benefitsLoading: status,
				benefitsLoadingError: error
			}
		},

		SET_V2_BENEFITS_AND_SELECTIONS_LOADING_STATUS(state, { status, error }) {
			return {
				...state,
				v2BenefitsAndSelectionsLoading: status
			}
		},

		SET_BENEFIT_ERROR(state, { benefitId, error })
		{
			return {
				...state,
				products: {
					...state.products,
					[benefitId]: {
						...state.products[benefitId],
						error
					}
				}
			}
		},

		SET_BEAM_SUPPORTED_BENEFITS(state, beamSupportedBenefits)
		{
			return {
				...state,
				beamSupportedBenefits
			}
		},

		SET_BENEFITS_LOADED(state, payload) {
			return {
				...state,
				benefitsLoaded: payload
			}
		},

		SET_CCV_ONLY(state, payload) {
			return {
				...state,
				ccvOnly: payload
			}
		},

		REMOVE_BENEFIT(state, payload) {
				const benefits = R.clone(state.products);
				delete benefits[payload.id];
				return {
					...state,
					products: benefits
				}
		},

		SET_BEAM_COSTS(state, payload) {
			return {
			  ...state,
			  beamCosts: payload,
			}
		  },

		SAVE_BEAM_COSTS_VALUES(state, payload) {
			return {
				...state,
				beamCostRequestValues: payload,
			}
		},

		SAVE_SELECTION_COST_CALCULATION_RESPONSE(state, payload) {
			return {
				...state,
				wizardResult: payload,
			}
		}

	},

	effects: dispatch => {
		return {

			async REQUEST_SELECTIONS() {
				const response = await fetchApi(restAPI.GET_SELECTIONS);

				const { error } = response;

				if (!error) {
					dispatch({
						type: 'benefits/ADD_SELECTIONS',
						payload: response
					})
				}
			},
			async REQUEST_BENEFITS() {
				dispatch({
					type: 'benefits/SET_BENEFITS_LOADING_STATUS',
					payload: { status: true }
				})

				const benefits = await fetchApi(restAPI.GET_BENEFITS)
				if (!benefits.error) {
						// This will be changed when we move selections over to BEAM
						const selections = []
						const beamSupportedBenefits = []

						if(benefits.length) {
							benefits.forEach(benefit => {
								if(benefit.selection) selections.push(benefit.selection)
								if(benefit.beamSupported) beamSupportedBenefits.push(benefit.benefitId)
							})

							dispatch({
								type: 'benefits/SET_BEAM_SUPPORTED_BENEFITS',
								payload: beamSupportedBenefits
							})

							dispatch({
								type: 'benefits/ADD_BENEFITS',
								payload: benefits
							})

							dispatch({
								type: 'benefits/ADD_SELECTIONS',
								payload: selections
							})
						}

						dispatch({
							type: 'benefits/REQUEST_FULL_V2_BENEFITS_AND_SELECTIONS',
							payload: beamSupportedBenefits
						})
				} else {
					dispatch({
						type: "user/LOGOUT",
						payload: m.GET_BENEFITS_AND_SELECTIONS_ERROR
					})
				}

				dispatch({
					type: 'benefits/SET_BENEFITS_LOADING_STATUS',
					payload: {
						status: false,
						error: benefits.error
					}
				})

			},

			async REQUEST_FULL_V2_BENEFITS_AND_SELECTIONS()
			{
				dispatch({
					type: 'benefits/SET_V2_BENEFITS_AND_SELECTIONS_LOADING_STATUS',
					payload: { status: true }
				})

				const { benefits, selections, error } = await fetchApi(restAPI.GET_V2_SELECTIONS_AND_BENEFITS)
				if (!error) {
					dispatch({
						type: 'benefits/ADD_BENEFITS',
						payload: benefits
					})

					dispatch({
						type: 'benefits/ADD_SELECTIONS',
						payload: selections
					})
				}

				dispatch({
					type: 'benefits/SET_V2_BENEFITS_AND_SELECTIONS_LOADING_STATUS',
					payload: {
						status: false
					}
				})

				const childCareProps = R.compose(
					R.find(v => v.groupCode === 'ccv'),
					R.values
				)(benefits)

				if (childCareProps) {
					dispatch({
						type: 'benefits/REQUEST_CCV_BALANCE'
					})
				}

				await dispatch({
					type: 'benefits/SET_BENEFITS_LOADED',
					payload: true
				})

				dispatch({
					type: 'benefits/SET_REFRESH_REQUIRED',
					payload: { benefitsAndSelections: false }
				})

			},

			async REQUEST_BEAM_BENEFIT(  {benefitId} )
			{
				const benefit = await fetchApi(restAPI.GET_BENEFIT(benefitId))

				if(!benefit.error)
				{
					await dispatch({
						type: 'benefits/ADD_BENEFIT',
						payload: benefit
					})
				}

				else
				{
					await dispatch({
						type: 'benefits/SET_BENEFIT_ERROR',
						payload: {
							benefitId,
							error: benefit.error
						}
					})

				}



			},

  		async RESET_SELECTION(id){
				const response = await fetchApi(restAPI.GET_SELECTION(id))
				const { error } = response

				if (!error) {
					const { benefitId } = response
					dispatch({
						type: 'benefits/ADD_SELECTION',
						payload: {
							id: benefitId,
							selection: response,
							temporarySelection: false
						}
					})
				}
			},
      
      async REQUEST_BENEFIT({ benefitId, beamSupportedBenefit }) {

				// eslint-disable-next-line prefer-const
				let [ benefit, selection ] = await fetchApi(restAPI.GET_V2_BENEFIT_AND_SELECTION(benefitId));

				if(beamSupportedBenefit)
					benefit = await fetchApi(restAPI.GET_BENEFIT(benefitId))

				await dispatch({
					type: 'benefits/ADD_BENEFIT',
					payload: benefit
				})

				await dispatch({
					type: 'benefits/ADD_SELECTION',
					payload: {
						id: benefitId,
						selection
					}
				})

			},

			async REQUEST_CCV_BALANCE() {
				const response = await fetchApi(restAPI.GET_CCV_BALANCE)
				const { error } = response

				if (!error) {
					dispatch({
						type: 'benefits/ADD_CCV_BALANCE',
						payload: response
					})
				}
			},

			async REQUEST_BENEFITS_FAQS(id) {
				const response = await fetchApi(restAPI.BENEFIT_FAQS(id))
				const { error } = response

				if (!error) {
					dispatch({
						type: 'benefits/ADD_BENEFIT_DETAILS',
						payload: {
							id,
							faqs: response
						}
					})
				}
			},

			async REQUEST_BENEFITS_RELATED(id) {
				try {
					const response = await fetchApi(restAPI.RELATED_BENEFITS(id))
					const { error } = response

					if (!error) {
						dispatch({
							type: 'benefits/ADD_BENEFIT_DETAILS',
							payload: {
								id,
								related: response
							}
						})
					}
				} catch (err) {
					console.error({ REQUEST_BENEFITS_RELATED: err }) // eslint-disable-line no-console
				}
			},

			async REQUEST_STATEMENT({ groupId, benefitId }) {
				const response = await fetchApi(restAPI.STATEMENT(groupId))
				const { error } = response

				if (!error && R.is(Array, response)) {
					const data = response
						.map(selection => ({
							month: selection.month,
							year: selection.year,
							employee: selection.employeeContribution,
							employer: selection.employerContribution,
							date: `${months[selection.month - 1]} ${selection.year}`,
							time: format(new Date(selection.year, selection.month, 1), 'T')
						}))
						.reverse()

					dispatch({
						type: 'benefits/ADD_STATEMENT',
						payload: {
							id: benefitId,
							data
						}
					})
				} else {
					dispatch({
						type: 'benefits/ADD_STATEMENT',
						payload: {
							id: benefitId,
							data: 'error'
						}
					})
				}
			},

			async REQUEST_TERMS(payload) {
				const { benefitId, termId } = payload
				const response = await fetchApi(restAPI.TERMS(payload))
				const { error, terms, version } = response

				const data = !error
					? { content: terms, version }
					: {
							error:
								'Sorry, there was an error requesting the terms and conditions'
					  }

				dispatch({
					type: 'benefits/SET_TERMS',
					payload: {
						benefitId,
						termId,
						value: data
					}
				})
			},
			async REQUEST_OPT_OUT(payload) {
				const response = await fetchApi(restAPI.OPT_OUT(payload), {
					method: 'PATCH',
					body: JSON.stringify({
						status: 'opted-out'
					})
				})

				const { error } = response

				if (!error) {
					const { benefitId } = response
					dispatch({
						type: 'benefits/ADD_SELECTION',
						payload: {
							id: benefitId,
							selection: response
						}
					})
				}
			},

			async POST_BENEFIT_SETUP({ id, selection, values, cancelToken }, context) {
				const setup = context.benefits.setup[id]
				const isReviewSubmission = typeof values.accepted_terms !== 'undefined'
				const ctaType = context.benefits.selections[id].cta.type

				const method = selection ? (ctaType === 'reapply' ? 'POST' : 'PATCH') : 'POST'

				const url = selection ?  (ctaType === 'reapply' ? restAPI.ADD_BENEFIT(id) : restAPI.EDIT_BENEFIT(selection)): restAPI.ADD_BENEFIT(id)

				const formData = isReviewSubmission
					? { ...setup.values, ...values }
					: values

				const response = await fetchApi(url, {
					method,
					body: JSON.stringify(formData),
					signal: cancelToken
				})

				const { error, status, message, ...data } = response
				const success = typeof error === 'undefined'

				if (status === 'error') {
					dispatch({
						type: 'benefits/SET_STATUS',
						payload: message
					})
					return
				}

				if (error && error.name === 'AbortError') {
					return
				}
				if (success) {
					// happy response
					const { benefitId, ...rest } = data
					if (!isReviewSubmission) {
						dispatch({
							type: 'benefits/SAVE_VALUES',
							payload: { id, values }
						})
					}

					dispatch({
						type: 'benefits/ADD_SELECTION',
						payload: { id, selection: rest, temporarySelection: !!data.selectionId }
					})
					
					dispatch({
						type: 'benefits/SET_STATUS',
						payload: true
					})

					if (values.accepted_terms)
					{


						dispatch({
							type: 'benefits/SET_REFRESH_REQUIRED',
							payload: {
								benefitsAndSelections: true,
								mtb: {
									card: true,
									page: true
								}
							}
						})

					}

				} else {
					dispatch({
						type: 'benefits/SET_STATUS',
						payload:
							error?.message || 'There was an error connecting to the server'
					})
				}
			},

			async BENEFITS_REFRESH() {

				await dispatch({
					type: 'benefits/REQUEST_BENEFITS',
				})

				await dispatch({
					type: 'benefits/SET_REFRESH_REQUIRED',
					payload: { benefitsAndSelections: false }
				})

			},

			async SELECTION_APPROVAL(payload) {
				const response = await fetchApi(restAPI.UPDATE_SELECTION_STATUS, {
					method: "PATCH",
					body: JSON.stringify(payload),
				})

			},

			async BEAM_SELECTION_COST_CALCULATION(payload) {
				const response = await fetchApi(restAPI.REQUEST_SELECTION_COST_CALCULATION_DATA, {
					method: "POST",
					body: JSON.stringify(payload),
				});

				if (response.error) {
					throw new Error(response.error);
				}

				dispatch({
					type: 'benefits/SET_BEAM_COSTS',
					payload: response
				});

				dispatch({
					type: 'benefits/SAVE_BEAM_COSTS_VALUES',
					payload: payload
				});
			},

			async BEAM_CREATE_OR_UPDATE_SELECTION_COST_CALCULATION({ client, employee, ...data }) {
				const response = await fetchApi(restAPI.CREATE_SELECTION_USING_BEAM_COST_CALCULATION_DATA(client, employee), {
					method: "POST",
					body: JSON.stringify(data.data),
				});

				dispatch({
					type: 'benefits/SAVE_SELECTION_COST_CALCULATION_RESPONSE',
					payload: response,
				});
				
				dispatch({
					type: 'benefits/SET_REFRESH_REQUIRED',
					payload: {
						benefitsAndSelections: true,
						mtb: {
							card: true,
							page: true
						}
					}
				})
			},

			async RESET_BEAM_COSTS_VALUES() {
				dispatch({
					type: 'benefits/RESET_BEAM_COSTS_REQUEST_VALUES'
				})
			},
		}
	}
}
